import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import toastr from "toastr";
import { SpinnerCircular } from "spinners-react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";

import {
  saveUserRequest,
  updateUserRequest,
} from "../../../../../actions/usersActions";

import {
  saveTeamRequest,
  saveSecondaryTeamRequest,
} from "../../../../../actions/generalActions";

/*********Icons********* */
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
/*******Services******* */
import TeamService from "../../Services/TeamService";
import UserService from "../../Services/UserService";
import "../../Styles/UserDialog.scss";
import tr from "date-fns/esm/locale/tr/index.js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "1rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: "50ch",
    marginBottom: "5px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function User(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [addGroup, setAddGroup] = useState(false);
  const [addSecondaryGroup, setSecondaryAddGroup] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [emailError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [teamError, setTeamError] = useState(false);
  const [secondaryTeamError, setSecondaryTeamError] = useState(false);
  const [secTeam, setSecTeam] = useState(null);
  const [secRolTeam, setSecRolTeam] = useState([]);
  const [showDirectorSelect, setShowDirectorSelect] = useState(false);
  const [showSrManagerSelect, setShowSrManagerSelect] = useState(false);
  const [showManagerSelect, setShowManagerSelect] = useState(false);
  const [showTeamManagerSelect, setShowTeamManagerSelect] = useState(false);
  const [showTeamLeaderSelect, setShowTeamLeaderSelect] = useState(false);
  const [teamLeader, setTeamLeader] = useState(null);
  const [teamManager, setTeamManager] = useState(null);
  const [srManager, setSrManager] = useState(null);
  const [director, setDirector] = useState(null);
  const [manager, setManager] = useState(null);
  const [colaborador, setColaborador] = useState(null);
  const [position, setPosition] = useState(null);
  const [addTeamError, setAddTeamError] = useState(false);
  const { t } = useTranslation();

  const [
    {
      username,
      name,
      lastName,
      email,
      role,
      user,
      users,
      team,
      teams,
      secondaryTeam,
      secondaryTeams,
      roles,
      addTeam,
      isActive,
      awardCategory,
    },
    setState,
  ] = useState({
    username: props.username ? props.username : "",
    name: props.name ? props.name : "",
    lastName: props.lastName ? props.lastName : "",
    email: props.email ? props.email : "",
    role: 3,
    team: { id: "NONE" },
    user: { id: "NONE" },
    secondaryTeam: { id: "NONE" },
    secondaryTeams: props.secondaryTeams,
    teams: props.teams,
    users: props.users,
    roles: props.roles ? props.roles : [],
    addTeam: "",
    isActive: props.isActive,
    awardCategory: props.awardCategory,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      teams: props.teams,
      users: props.users,
      secondaryTeams: props.secondaryTeams,
    }));
  }, [props.teams, props.secondaryTeams, props.users]);

  useEffect(() => {
    const secondaryTeamFromProps =
      props.roles && props.roles.length > 0 && props.roles[0].secondaryTeam;
    if (secondaryTeamFromProps) {
      setState((prevState) => ({
        ...prevState,
        secondaryTeam: secondaryTeamFromProps,
      }));
      setSecTeam(secondaryTeamFromProps);
    }
  }, [props.roles]);

  useEffect(() => {
    if (props.ownSecondaryTeam) {
      setSecRolTeam((oldState) => [...oldState, props.ownSecondaryTeam]);
      setSecTeam(props.ownSecondaryTeam);
    }
  }, [props.ownSecondaryTeam]);

  const handleClickOpen = () => {
    setOriginalState();

    setOpen(true);
  };
  const handleClickOpen2 = () => {
    
      setOriginalState2();
  
      setOpen(true);
  
  };
  const handleClose = () => {
    setOpen(false);
  };

  function setOriginalState2() {
    // Reiniciar todos los valores de los selectores
    setPosition(props.position);
  
    // Establecer las condiciones de visualización de acuerdo con la posición del usuario
    setShowDirectorSelect(true);
    setShowSrManagerSelect(true);
    setShowManagerSelect(true);
    setShowTeamManagerSelect(true);
    setShowTeamLeaderSelect(true);
  
    // Detectar la posición del usuario y ajustar las condiciones de visualización según sea necesario
    if (props.position === 6) {
      // Establecer la visibilidad para la posición 3
      setShowDirectorSelect(false);
      setShowSrManagerSelect(false);
      setShowManagerSelect(false);
      setShowTeamManagerSelect(false);
      setShowTeamLeaderSelect(false);
      
      // Establecer el valor del selector colaborador como "director"
      setColaborador("director");
      const teamLeaders = users.filter(user => user.position === 2);
      const managers = users.filter(user => user.position === 4);
      const srManagers = users.filter(user => user.position === 5);
      const teamManagers = users.filter(user => user.position === 1);
      const directors = users.filter(user => user.position === 6);
    
      // Verificar si el usuario tiene un líder de equipo asignado
      if (props.teamLeader && teamLeaders.length > 0) {
        // Buscar el usuario asignado como líder del equipo
        const assignedTeamLeader = teamLeaders.find(leader => leader.id === props.teamLeader.id);
    
        // Asignar el usuario asignado como líder del equipo si se encuentra en la lista filtrada
        if (assignedTeamLeader) {
          setTeamLeader(assignedTeamLeader);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamLeader();
        }
      } else {
        // Si el usuario no tiene ningún líder de equipo asignado, establecer el valor NONE
        setTeamLeader();
      }
    
      // Verificar si el usuario tiene un gerente asignado
      if (props.manager && managers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedManager = managers.find(manager => manager.id === props.manager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedManager) {
          setManager(assignedManager);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setManager();
      }
      if (props.srManager && srManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsrManagers = srManagers.find(manager => manager.id === props.srManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsrManagers) {
          setSrManager(assignedsrManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setSrManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setSrManager();
      }
      if (props.teamManager && teamManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsteamManagers = teamManagers.find(manager => manager.id === props.teamManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsteamManagers) {
          setTeamManager(assignedsteamManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setTeamManager();
      }
      if (props.director && directors.length > 0) {
        // Buscar el usuario asignado como gerente
        const assigneddirectors = directors.find(manager => manager.id === props.director.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assigneddirectors) {
          setDirector(assigneddirectors);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setDirector();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setDirector();
      }
    }else if (props.position === 3) {
  setShowDirectorSelect(true);
  setShowSrManagerSelect(true);
  setShowManagerSelect(true);
  setShowTeamManagerSelect(true);
  setShowTeamLeaderSelect(true);
  
  // Establecer la visibilidad para otra posición
  setColaborador("colaborador");

  // Filtrar usuarios por posición de liderazgo del equipo (posición 2) y gerente (posición 4)
  const teamLeaders = users.filter(user => user.position === 2);
  const managers = users.filter(user => user.position === 4);
  const srManagers = users.filter(user => user.position === 5);
  const teamManagers = users.filter(user => user.position === 1);
  const directors = users.filter(user => user.position === 6);

  // Verificar si el usuario tiene un líder de equipo asignado
  if (props.teamLeader && teamLeaders.length > 0) {
    // Buscar el usuario asignado como líder del equipo
    const assignedTeamLeader = teamLeaders.find(leader => leader.id === props.teamLeader.id);

    // Asignar el usuario asignado como líder del equipo si se encuentra en la lista filtrada
    if (assignedTeamLeader) {
      setTeamLeader(assignedTeamLeader);
    } else {
      // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
      setTeamLeader();
    }
  } else {
    // Si el usuario no tiene ningún líder de equipo asignado, establecer el valor NONE
    setTeamLeader();
  }

  // Verificar si el usuario tiene un gerente asignado
  if (props.manager && managers.length > 0) {
    // Buscar el usuario asignado como gerente
    const assignedManager = managers.find(manager => manager.id === props.manager.id);

    // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
    if (assignedManager) {
      setManager(assignedManager);
    } else {
      // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
      setManager();
    }
  } else {
    // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
    setManager();
  }
  if (props.srManager && srManagers.length > 0) {
    // Buscar el usuario asignado como gerente
    const assignedsrManagers = srManagers.find(manager => manager.id === props.srManager.id);

    // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
    if (assignedsrManagers) {
      setSrManager(assignedsrManagers);
    } else {
      // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
      setSrManager();
    }
  } else {
    // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
    setSrManager();
  }
  if (props.teamManager && teamManagers.length > 0) {
    // Buscar el usuario asignado como gerente
    const assignedsteamManagers = teamManagers.find(manager => manager.id === props.teamManager.id);

    // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
    if (assignedsteamManagers) {
      setTeamManager(assignedsteamManagers);
    } else {
      // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
      setTeamManager();
    }
  } else {
    // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
    setTeamManager();
  }
  if (props.director && directors.length > 0) {
    // Buscar el usuario asignado como gerente
    const assigneddirectors = directors.find(manager => manager.id === props.director.id);

    // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
    if (assigneddirectors) {
      setDirector(assigneddirectors);
    } else {
      // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
      setDirector();
    }
  } else {
    // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
    setDirector();
  }

}

    
    
    else if (props.position === 2) {
      setShowDirectorSelect(true);
      setShowSrManagerSelect(true);
      setShowManagerSelect(true);
      setShowTeamManagerSelect(true);
      setShowTeamLeaderSelect(false);
      
      // Establecer la visibilidad para otra posición
      setColaborador("team-leader");
      // ...
      const teamLeaders = users.filter(user => user.position === 2);
      const managers = users.filter(user => user.position === 4);
      const srManagers = users.filter(user => user.position === 5);
      const teamManagers = users.filter(user => user.position === 1);
      const directors = users.filter(user => user.position === 6);
    
      // Verificar si el usuario tiene un líder de equipo asignado
      if (props.teamLeader && teamLeaders.length > 0) {
        // Buscar el usuario asignado como líder del equipo
        const assignedTeamLeader = teamLeaders.find(leader => leader.id === props.teamLeader.id);
    
        // Asignar el usuario asignado como líder del equipo si se encuentra en la lista filtrada
        if (assignedTeamLeader) {
          setTeamLeader(assignedTeamLeader);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamLeader();
        }
      } else {
        // Si el usuario no tiene ningún líder de equipo asignado, establecer el valor NONE
        setTeamLeader();
      }
    
      // Verificar si el usuario tiene un gerente asignado
      if (props.manager && managers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedManager = managers.find(manager => manager.id === props.manager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedManager) {
          setManager(assignedManager);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setManager();
      }
      if (props.srManager && srManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsrManagers = srManagers.find(manager => manager.id === props.srManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsrManagers) {
          setSrManager(assignedsrManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setSrManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setSrManager();
      }
      if (props.teamManager && teamManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsteamManagers = teamManagers.find(manager => manager.id === props.teamManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsteamManagers) {
          setTeamManager(assignedsteamManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setTeamManager();
      }
      if (props.director && directors.length > 0) {
        // Buscar el usuario asignado como gerente
        const assigneddirectors = directors.find(manager => manager.id === props.director.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assigneddirectors) {
          setDirector(assigneddirectors);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setDirector();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setDirector();
      }
    }
    else if (props.position === 1) {
      setShowDirectorSelect(true);
      setShowSrManagerSelect(true);
      setShowManagerSelect(true);
      setShowTeamManagerSelect(false);
      setShowTeamLeaderSelect(false);
      // Establecer la visibilidad para otra posición
      setColaborador("team-manager");
      const teamLeaders = users.filter(user => user.position === 2);
      const managers = users.filter(user => user.position === 4);
      const srManagers = users.filter(user => user.position === 5);
      const teamManagers = users.filter(user => user.position === 1);
      const directors = users.filter(user => user.position === 6);
    
      // Verificar si el usuario tiene un líder de equipo asignado
      if (props.teamLeader && teamLeaders.length > 0) {
        // Buscar el usuario asignado como líder del equipo
        const assignedTeamLeader = teamLeaders.find(leader => leader.id === props.teamLeader.id);
    
        // Asignar el usuario asignado como líder del equipo si se encuentra en la lista filtrada
        if (assignedTeamLeader) {
          setTeamLeader(assignedTeamLeader);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamLeader();
        }
      } else {
        // Si el usuario no tiene ningún líder de equipo asignado, establecer el valor NONE
        setTeamLeader();
      }
    
      // Verificar si el usuario tiene un gerente asignado
      if (props.manager && managers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedManager = managers.find(manager => manager.id === props.manager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedManager) {
          setManager(assignedManager);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setManager();
      }
      if (props.srManager && srManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsrManagers = srManagers.find(manager => manager.id === props.srManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsrManagers) {
          setSrManager(assignedsrManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setSrManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setSrManager();
      }
      if (props.teamManager && teamManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsteamManagers = teamManagers.find(manager => manager.id === props.teamManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsteamManagers) {
          setTeamManager(assignedsteamManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setTeamManager();
      }
      if (props.director && directors.length > 0) {
        // Buscar el usuario asignado como gerente
        const assigneddirectors = directors.find(manager => manager.id === props.director.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assigneddirectors) {
          setDirector(assigneddirectors);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setDirector();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setDirector();
      }
      // ...
    }
    else if (props.position === 4) {
      setShowDirectorSelect(true);
      setShowSrManagerSelect(true);
      setShowManagerSelect(false);
      setShowTeamManagerSelect(false);
      setShowTeamLeaderSelect(false);
      // Establecer la visibilidad para otra posición
      setColaborador("manager");
      const teamLeaders = users.filter(user => user.position === 2);
      const managers = users.filter(user => user.position === 4);
      const srManagers = users.filter(user => user.position === 5);
      const teamManagers = users.filter(user => user.position === 1);
      const directors = users.filter(user => user.position === 6);
    
      // Verificar si el usuario tiene un líder de equipo asignado
      if (props.teamLeader && teamLeaders.length > 0) {
        // Buscar el usuario asignado como líder del equipo
        const assignedTeamLeader = teamLeaders.find(leader => leader.id === props.teamLeader.id);
    
        // Asignar el usuario asignado como líder del equipo si se encuentra en la lista filtrada
        if (assignedTeamLeader) {
          setTeamLeader(assignedTeamLeader);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamLeader();
        }
      } else {
        // Si el usuario no tiene ningún líder de equipo asignado, establecer el valor NONE
        setTeamLeader();
      }
    
      // Verificar si el usuario tiene un gerente asignado
      if (props.manager && managers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedManager = managers.find(manager => manager.id === props.manager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedManager) {
          setManager(assignedManager);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setManager();
      }
      if (props.srManager && srManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsrManagers = srManagers.find(manager => manager.id === props.srManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsrManagers) {
          setSrManager(assignedsrManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setSrManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setSrManager();
      }
      if (props.teamManager && teamManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsteamManagers = teamManagers.find(manager => manager.id === props.teamManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsteamManagers) {
          setTeamManager(assignedsteamManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setTeamManager();
      }
      if (props.director && directors.length > 0) {
        // Buscar el usuario asignado como gerente
        const assigneddirectors = directors.find(manager => manager.id === props.director.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assigneddirectors) {
          setDirector(assigneddirectors);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setDirector();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setDirector();
      }
      // ...
    }
    else if (props.position === 5) {
      setShowDirectorSelect(true);
      setShowSrManagerSelect(false);
      setShowManagerSelect(false);
      setShowTeamManagerSelect(false);
      setShowTeamLeaderSelect(false);
      // Establecer la visibilidad para otra posición
      setColaborador("sr-manager");
      // ...
      const teamLeaders = users.filter(user => user.position === 2);
      const managers = users.filter(user => user.position === 4);
      const srManagers = users.filter(user => user.position === 5);
      const teamManagers = users.filter(user => user.position === 1);
      const directors = users.filter(user => user.position === 6);
    
      // Verificar si el usuario tiene un líder de equipo asignado
      if (props.teamLeader && teamLeaders.length > 0) {
        // Buscar el usuario asignado como líder del equipo
        const assignedTeamLeader = teamLeaders.find(leader => leader.id === props.teamLeader.id);
    
        // Asignar el usuario asignado como líder del equipo si se encuentra en la lista filtrada
        if (assignedTeamLeader) {
          setTeamLeader(assignedTeamLeader);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamLeader();
        }
      } else {
        // Si el usuario no tiene ningún líder de equipo asignado, establecer el valor NONE
        setTeamLeader();
      }
    
      // Verificar si el usuario tiene un gerente asignado
      if (props.manager && managers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedManager = managers.find(manager => manager.id === props.manager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedManager) {
          setManager(assignedManager);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setManager();
      }
      if (props.srManager && srManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsrManagers = srManagers.find(manager => manager.id === props.srManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsrManagers) {
          setSrManager(assignedsrManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setSrManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setSrManager();
      }
      if (props.teamManager && teamManagers.length > 0) {
        // Buscar el usuario asignado como gerente
        const assignedsteamManagers = teamManagers.find(manager => manager.id === props.teamManager.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assignedsteamManagers) {
          setTeamManager(assignedsteamManagers);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setTeamManager();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setTeamManager();
      }
      if (props.director && directors.length > 0) {
        // Buscar el usuario asignado como gerente
        const assigneddirectors = directors.find(manager => manager.id === props.director.id);
    
        // Asignar el usuario asignado como gerente si se encuentra en la lista filtrada
        if (assigneddirectors) {
          setDirector(assigneddirectors);
        } else {
          // Si el usuario asignado no se encuentra en la lista filtrada, establecer el valor NONE
          setDirector();
        }
      } else {
        // Si el usuario no tiene ningún gerente asignado, establecer el valor NONE
        setDirector();
      }
    }
     else {
      // En otras posiciones, mantener el valor seleccionado como null
      setColaborador(null);
    }

  // Establecer otros valores según sea necesario
  setState((prevState) => ({
    ...prevState,
    username: props.username ? props.username : "",
    name: props.name ? props.name : "",
    lastName: props.lastName ? props.lastName : "",
    email: props.email ? props.email : "",
    role: 3,
    team: { id: "NONE" },
    user: { id: "NONE" },
    secondaryTeam: { id: "NONE" },
    secondaryTeams: props.secondaryTeams,
    teams: props.teams,
    users: props.users,
    roles: props.roles ? props.roles : [],
    addTeam: "",
    isActive: props.isActive,
    awardCategory: props.awardCategory
  }));
}

  function setOriginalState() {
    setTeamLeader(null);
    setTeamManager(null);
    setSrManager(null);
    setDirector(null);
    setManager(null);
    setColaborador(null);
    setShowDirectorSelect(false);
    setShowSrManagerSelect(false);
    setShowManagerSelect(false);
    setShowTeamManagerSelect(false);
    setShowTeamLeaderSelect(false);
    setState((prevState) => ({
      ...prevState,
      username: props.username ? props.username : "",
      name: props.name ? props.name : "",
      lastName: props.lastName ? props.lastName : "",
      email: props.email ? props.email : "",
      role: 3,
      team: { id: "NONE" },
      user: { id: "NONE" },
      secondaryTeam: { id: "NONE" },
      secondaryTeams: props.secondaryTeams,
      teams: props.teams,
      users: props.users,
      roles: props.roles ? props.roles : [],
      addTeam: "",
      isActive: props.isActive,
      awardCategory: props.awardCategory
    }));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === "colaborador") {
      // Si el valor seleccionado es "NONE", establece el colaborador en null
      // De lo contrario, establece el valor seleccionado
      setColaborador(value === "NONE" ? null : value);
  
      // Si el valor seleccionado es "NONE", también puedes restablecer los valores de los otros selectores a null
      if (value === "NONE") {
        setTeamLeader(null);
        setTeamManager(null);
        setSrManager(null);
        setDirector(null);
        setManager(null);
        setShowDirectorSelect(false);
        setShowSrManagerSelect(false);
        setShowManagerSelect(false);
        setShowTeamManagerSelect(false);
        setShowTeamLeaderSelect(false);
        setPosition(3);
      } else {
        // Aquí agregamos lógica para establecer otros selectores según el valor seleccionado en "colaborador"
        if (value === "director") {
          setShowDirectorSelect(false);
          setShowSrManagerSelect(false);
          setShowManagerSelect(false);
          setShowTeamManagerSelect(false);
          setShowTeamLeaderSelect(false);
          setPosition(6);
          // Agregar aquí lógica para ocultar los otros selectores si es necesario
        } else if (value === "sr-manager") {
          setShowDirectorSelect(true);
          setShowSrManagerSelect(false);
          setShowManagerSelect(false);
          setShowTeamManagerSelect(false);
          setShowTeamLeaderSelect(false);
          setPosition(5);
          // Agregar aquí lógica para ocultar los otros selectores si es necesario
        } else if (value === "manager") {
          // Lógica para mostrar el selector de manager y ocultar los demás
          setShowDirectorSelect(true);
          setShowSrManagerSelect(true);
          setShowManagerSelect(false);
          setShowTeamManagerSelect(false);
          setShowTeamLeaderSelect(false);
          setPosition(4);
        } else if (value === "team-manager") {
          setShowDirectorSelect(true);
          setShowSrManagerSelect(true);
          setShowManagerSelect(true);
          setShowTeamManagerSelect(false);
          setShowTeamLeaderSelect(false);
          setPosition(1);
          // Lógica para mostrar el selector de team-manager y ocultar los demás
        } else if (value === "team-leader") {
          setShowDirectorSelect(true);
          setShowSrManagerSelect(true);
          setShowManagerSelect(true);
          setShowTeamManagerSelect(true);
          setShowTeamLeaderSelect(false);
          setPosition(2);
          // Lógica para mostrar el selector de team-leader y ocultar los demás
        } else if (value === "colaborador") {
          setShowDirectorSelect(true);
          setShowSrManagerSelect(true);
          setShowManagerSelect(true);
          setShowTeamManagerSelect(true);
          setShowTeamLeaderSelect(true);
          setPosition(3);
          // Lógica para mostrar el selector de team-leader y ocultar los demás
        } else {
          setTeamLeader(false);
          setTeamManager(false);
          setManager(false);
          setSrManager(false);
          setDirector(false);
          setShowDirectorSelect(false);
          setShowSrManagerSelect(false);
          setShowManagerSelect(false);
          setShowTeamManagerSelect(false);
          setShowTeamLeaderSelect(false);
          // Agregar aquí lógica para ocultar los otros selectores si es necesario
        }
      }
    } else if (name === "team") {
      setState((prevState) => ({
        ...prevState,
        team: { id: value },
      }));
    } else if (name === "team-leader") {
      setTeamLeader((prevLeader) =>
        value === "NONE" ? null : { ...prevLeader, id: value }
      );
    } else if (name === "team-manager") {
      setTeamManager((prevLeader) =>
        value === "NONE" ? null : { ...prevLeader, id: value }
      );
    } else if (name === "sr-manager") {
      setSrManager((prevLeader) =>
        value === "NONE" ? null : { ...prevLeader, id: value }
      );
    } else if (name === "director") {
      setDirector((prevLeader) =>
        value === "NONE" ? null : { ...prevLeader, id: value }
      );
    } else if (name === "manager") {
      setManager((prevLeader) =>
        value === "NONE" ? null : { ...prevLeader, id: value }
      );
    } else if (name === "secondaryTeam") {
      if (value) {
        setState((prevState) => ({
          ...prevState,
          secondaryTeam: { id: value },
        }));
  
        const secTeamFound = secondaryTeams.find(
          (element) => element.id === value
        );
  
        if (secTeamFound) {
          if (secTeamFound.id !== "NONE") {
            setSecTeam(secTeamFound);
          } else {
            setSecTeam(null);
          }
        } else {
          setSecTeam(null);
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          secondaryTeam: { id: "NONE" },
        }));
      }
    }
  
    // Otras lógicas para manejar cambios en otros selectores...
  };
  
  

  /***********ADD Group********** */
  function addGroupFunction() {
    setSecondaryAddGroup(false);
    setAddGroup(!addGroup);
    setState((prevState) => ({ ...prevState, addTeam: "" }));
  }
  function addSecondaryGroupFunction() {
    setAddGroup(false);
    setSecondaryAddGroup(!addSecondaryGroup);
    setState((prevState) => ({ ...prevState, addTeam: "" }));
  }

  function saveGroup(type = "PRINCIPAL") {
    setAddTeamError(false);
    if (addTeam === "" || !addTeam) {
      setAddTeamError(true);
      toastr.error(
        t(
          "admin.user-panel-user-dialog-addTeam-input-error",
          "Group name is required"
        )
      );
      return;
    }
    setLoading(true);

    if (type === "SECONDARY") {
      TeamService.setSecondaryTeam({
        name: addTeam,
      })
        .then((response) => {
          let responseData = response.data;

          setState((prevState) => ({
            ...prevState,
            secondaryTeam: responseData,
            addTeam: "",
            secondaryTeams: secondaryTeams.concat(responseData),
          }));

          props.saveSecondaryTeamRequest(responseData);

          toastr.success(
            t(
              "admin.user-panel-user-dialog-message-success-addTeam",
              "Team saved successfully"
            )
          );
          setLoading(false);
          setSecondaryAddGroup(!addSecondaryGroup);
          setAddTeamError(false);
        })
        .catch(() => {
          toastr.error(
            t(
              "admin.user-panel-user-dialog-message-error-addTeam",
              "An error occurred while trying to save the group"
            )
          );

          setLoading(false);
        });
    } else {
      TeamService.setTeam({
        name: addTeam,
      })
        .then((response) => {
          let responseData = response.data;

          setState((prevState) => ({
            ...prevState,
            team: responseData,
            addTeam: "",
            teams: teams.concat(responseData),
          }));

          props.saveTeamRequest(responseData);
          toastr.success(
            t(
              "admin.user-panel-user-dialog-message-success-addTeam",
              "Team saved successfully"
            )
          );
          setLoading(false);
          setAddGroup(!addGroup);
          setAddTeamError(false);
        })
        .catch(() => {
          toastr.error(
            t(
              "admin.user-panel-user-dialog-message-error-addTeam",
              "An error occurred while trying to save the group"
            )
          );

          setLoading(false);
        });
    }
  }

  /***********Data Binding Form******* */

  function handleTextFieldChange({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function handleTeam({ target: { name, value } }) {
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  function addRole() {
    const teamFound = teams.find((element) => element.id === team.id);
    if (!teamFound || teamFound === "undefined") {
      toastr.error(
        t(
          "admin.user-panel-user-dialog-message-select-group",
          "You must select a group"
        )
      );
      return;
    }

    let roleFound = [...roles].find(
      (element) => element.role === role && element.team.id === teamFound.id
    );

    if (!roleFound || roleFound === "undefined") {
      let roleToAdd = {
        team: teamFound,
        role: role,
        isDefault: roles && roles.length > 0 ? false : true,
        isActive: true,
      };

      setState((prevState) => ({
        ...prevState,
        roles: [...roles, roleToAdd],
      }));
    } else {
      toastr.error(
        t(
          "admin.user-panel-user-dialog-message-selected-rol",
          "You already have that role in the group you chose"
        )
      );
    }
  }

  function addRoleSecondary() {
    if (!secTeam) {
      toastr.error(
        t(
          "admin.user-panel-user-dialog-message-select-secondary-group",
          "You must select a secondary group"
        )
      );
      return;
    }

    setSecRolTeam((oldState) => [...oldState, secTeam]);
  }

  function updateListRol(rol, index, type = "DEFAULT") {
    var rolesArray = [];
    if (type === "DELETE") {
      rolesArray = [...roles].filter((row) => row.id !== rol.id);
    } else if (type === "ACTIVE") {
      rolesArray = [...roles];
      rolesArray[index].isActive = !rolesArray[index].isActive;
    } else {
      rolesArray = [...roles];
      if (rolesArray[index].isDefault === false) {
        var rolesArrayDefault = [...roles];
        if (rolesArrayDefault && rolesArrayDefault.length > 0) {
          rolesArrayDefault.forEach((element) => {
            return (element.isDefault = false);
          });
          rolesArray = rolesArrayDefault;
        }
      }

      rolesArray[index].isDefault = !rolesArray[index].isDefault;
    }
    setState((prevState) => ({
      ...prevState,
      roles: [...rolesArray],
    }));
  }

  async function onFormSubmit() {
    /*********Remove Styles**** */

    setUsernameError(false);
    setRoleError(false);
    setTeamError(false);
    setSecondaryTeamError(false);

    if (username === "") {
      setUsernameError(true);
      toastr.error(
        t(
          "admin.user-panel-user-dialog-username-input-error",
          "Username is required"
        )
      );
      return;
    }

    if (roles === "" || roles.length === 0) {
      setRoleError(true);
      toastr.error(
        t(
          "admin.user-panel-user-dialog-role-input-error",
          "The role is required"
        )
      );
      return;
    }
    setLoading(true);

    let status;

    if (props.typeModal === "modalEdit") {
      await props
        .updateUserRequest({
          id: props.id,
          username,
          name,
          lastName,    
          teamLeader,
          teamManager,
          srManager,
          director,
          manager,
          email,
          position,
          roles,
          secondaryTeam:
            secRolTeam && secRolTeam.length > 0 ? { id: secTeam.id } : null,
          isActive,
          awardCategory: awardCategory
        })
        .then((r) => (status = r));
    } else {
      await props
        .saveUserRequest({
          username,
          name,
          lastName,       
          teamLeader,
          teamManager,
          srManager,
          director,
          manager,
          email,
          position,
          roles,
          secondaryTeam:
            secRolTeam && secRolTeam.length > 0 ? { id: secTeam.id } : null,
          isActive,
          awardCategory: awardCategory
        })
        .then((r) => (status = r));
    }

    if (status.status === "ERROR") {
      if (
        status.error &&
        status.error.data.messageCode === "invalid.existuseremail"
      ) {
        toastr.error(
          t(
            "admin.user-panel-user-dialog-message-error-user-exists-edit",
            "The email already exists in the database"
          )
        );
      } else if (
        status.error &&
        status.error.data.messageCode === "invalid.existusername"
      ) {
        toastr.error(
          t(
            "admin.user-panel-user-dialog-message-error-username-exists",
            "A user with this username already exists"
          )
        );
      } else {
        toastr.error(
          t(
            "admin.user-panel-user-dialog-message-error-user-save",
            "An error occurred while trying to save the user"
          )
        );
      }
      setLoading(false);
    } else {
      toastr.success(
        t(
          "admin.user-panel-user-dialog-message-success-user-save",
          "User saved successfully"
        )
      );
      setLoading(false);
      handleClose();
    }
  }

  return (
    <>
      <>
        {props.typeModal === "modalEdit" ? (
          <Tooltip title={t("admin.actions-edit", "Edit")}>
            <IconButton aria-label="edit-user" onClick={handleClickOpen2}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : (
          <Button
            className="button-table"
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            <AddIcon />
            {t("menu.user-panel-button-users", "Users")}
          </Button>
        )}

        <Dialog
          maxWidth="sm"
          fullWidth={true}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {t("admin.user-panel-user-dialog-title", "User Management")}
          </DialogTitle>

          {loading && (
            <div className="div-spinner-modal">
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <SpinnerCircular size={90} />
                </Grid>
              </DialogContent>
            </div>
          )}
          {!loading && (
            <>
              <DialogContent dividers>
                <TextField
                  error={usernameError}
                  value={username}
                  name="username"
                  onChange={handleTextFieldChange}
                  id="username"
                  label={t(
                    "admin.user-panel-user-dialog-input-username",
                    "Username"
                  )}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                <TextField
                  value={name}
                  name="name"
                  onChange={handleTextFieldChange}
                  id="name"
                  label={t("admin.user-panel-user-dialog-input-name", "Name")}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                <TextField
                  value={lastName}
                  name="lastName"
                  onChange={handleTextFieldChange}
                  id="lastName"
                  label={t(
                    "admin.user-panel-user-dialog-input-lastname",
                    "Last name"
                  )}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                <TextField
                  error={emailError}
                  value={email}
                  name="email"
                  onChange={handleTextFieldChange}
                  id="email"
                  label={t("admin.user-panel-table-email", "Email")}
                  variant="outlined"
                  className="mt-2"
                  fullWidth
                />

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="isActive-select-outlined-label">
                    {t("enabled-locked", "Habilitado/bloqueado")}
                  </InputLabel>
                  <Select
                    labelId="isActive"
                    id="isActive"
                    value={isActive}
                    name="isActive"
                    label={t("enabled-locked", "Habilitado/bloqueado")}
                    onChange={handleTextFieldChange}
                  >
                    <MenuItem value={true}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-yes",
                        "Yes"
                      )}
                    </MenuItem>
                    <MenuItem value={false}>
                      {t(
                        "admin.header-dropdown-dialog-notifications-input-item-no",
                        "No"
                      )}
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="role-select-outlined-label">
                    {t("menu.award-category", "Award Category")}
                  </InputLabel>
                  <Select
                    //error={roleError}
                    value={awardCategory}
                    name="awardCategory"
                    label={t(
                      "admin.user-panel-user-dialog-input-role",
                      "Role"
                    )}
                    onChange={handleTextFieldChange}
                  >
                    <MenuItem value={'Lideres'}>
                      Lider
                    </MenuItem>
                    <MenuItem value={'Agentes'}>
                      Agente
                    </MenuItem>
                    <MenuItem value={'Otros Roles'}>
                      Otro
                    </MenuItem>
                    <MenuItem value={''}></MenuItem>
                  </Select>
                </FormControl>

                {(props.typeModal &&
                  props.typeModal === "modalEdit" &&
                  !props.typeModal) ||
                role !== 1 ? (
                  <div>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="role-select-outlined-label">
                        {t("admin.user-panel-user-dialog-input-role", "Role")}
                      </InputLabel>
                      <Select
                        error={roleError}
                        labelId="role"
                        id="role"
                        value={role}
                        name="role"
                        label={t(
                          "admin.user-panel-user-dialog-input-role",
                          "Role"
                        )}
                        onChange={handleTextFieldChange}
                      >
                        <MenuItem value={2}>
                          {t("admin.user-panel-table-supervisor", "Supervisor")}{" "}
                        </MenuItem>
                        <MenuItem value={3}>
                          {t("admin.user-panel-table-player", "Player")}
                        </MenuItem>

                        <MenuItem value={4}></MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {!addGroup ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className="button-group"
                        onClick={addGroupFunction}
                      >
                        <AddIcon />
                        {t("admin.user-panel-user-dialog-input-group", "Group")}
                      </Button>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="team-select-outlined-label">
                          {t(
                            "admin.user-panel-user-dialog-input-group",
                            "Group"
                          )}
                        </InputLabel>
                        <Select
                          error={teamError}
                          labelId="team"
                          id="team"
                          value={team.id || ""}
                          name="team"
                          label={t(
                            "admin.user-panel-user-dialog-input-group",
                            "Group"
                          )}
                          onChange={handleChange}
                        >
                          <MenuItem value="NONE">
                            <em>
                              {t(
                                "admin.user-panel-user-dialog-input-select-without-group",
                                "Without group"
                              )}
                            </em>
                          </MenuItem>

                          {teams.map((team) => (
                            <MenuItem key={team.id} value={team.id}>
                              {team.name
                                ? team.name
                                : t(
                                    "admin.user-panel-user-dialog-input-select-without-name",
                                    "Without name"
                                  )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <TextField
                        error={addTeamError}
                        value={addTeam}
                        name="addTeam"
                        id="addTeam"
                        label={t(
                          "admin.user-panel-user-dialog-input-addTeam",
                          "Group name"
                        )}
                        variant="outlined"
                        className="mt-2"
                        fullWidth
                        onChange={handleTeam}
                      />

                      <div style={{ display: "flex", gap: "8px" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          className="button-table button-group"
                          onClick={() => setAddGroup(!addGroup)}
                        >
                          {t(
                            "admin.header-dropdown-dialog-actions-cancel",
                            "Cancel"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="button-table button-group"
                          onClick={saveGroup}
                        >
                          {t(
                            "admin.header-dropdown-dialog-actions-save",
                            "Save"
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
                {!addSecondaryGroup ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button-group"
                      onClick={addSecondaryGroupFunction}
                    >
                      <AddIcon />
                      {t(
                        "admin.user-panel-user-dialog-input-secondary-team",
                        "Secondary group"
                      )}
                    </Button>

                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="team-select-outlined-label">
                        {t(
                          "admin.user-panel-user-dialog-input-secondary-team",
                          "Secondary group"
                        )}
                      </InputLabel>
                      <Select
                        error={secondaryTeamError}
                        labelId="secondaryTeam"
                        id="secondaryTeam"
                        value={secondaryTeam.id || ""}
                        name="secondaryTeam"
                        label={t(
                          "admin.user-panel-user-dialog-input-secondary-team",
                          "Secondary group"
                        )}
                        onChange={handleChange}
                      >
                        <MenuItem value="NONE">
                          <em>
                            {t(
                              "admin.user-panel-user-dialog-input-select-without--secondary-group",
                              "No secondary group"
                            )}
                          </em>
                        </MenuItem>

                        {secondaryTeams.map((secondaryTeam) => (
                          <MenuItem
                            key={secondaryTeam.id}
                            value={secondaryTeam.id}
                          >
                            {secondaryTeam.name
                              ? secondaryTeam.name
                              : t(
                                  "admin.user-panel-user-dialog-input-select-without-name",
                                  "Without name"
                                )}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/******************Add role*********** */}
                    <Button
                      variant="contained"
                      color="primary"
                      className="button-table button-group"
                      onClick={() => addRole()}
                    >
                      <AddIcon />
                      {t("admin.user-panel-user-dialog-input-role", "Role") +
                        " / " +
                        t("team.label", "Team")}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button-table button-group ml-1"
                      onClick={() => addRoleSecondary()}
                      disabled={secRolTeam && secRolTeam.length > 0}
                    >
                      <AddIcon />
                      {t("admin.user-panel-user-dialog-input-role", "Role") +
                        " / " +
                        t(
                          "admin.user-panel-user-dialog-input-secondary-team",
                          "Secondary group"
                        )}
                    </Button>
                  </>
                ) : (
                  <div>
                    <TextField
                      error={addTeamError}
                      value={addTeam}
                      name="addTeam"
                      id="addTeam"
                      label={t(
                        "admin.user-panel-user-dialog-input-addTeam",
                        "Group name"
                      )}
                      variant="outlined"
                      className="mt-2"
                      fullWidth
                      onChange={handleTeam}
                    />

                    <Button
                      variant="contained"
                      color="secondary"
                      className="button-table button-group"
                      onClick={() => setSecondaryAddGroup(!addSecondaryGroup)}
                    >
                      {t(
                        "admin.header-dropdown-dialog-actions-cancel",
                        "Cancel"
                      )}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button-table button-group ml-1"
                      onClick={() => saveGroup("SECONDARY")}
                    >
                      {t("admin.header-dropdown-dialog-actions-save", "Save")}
                    </Button>
                  </div>
                )}

                
                <FormControl variant="outlined" className={classes.formControl}>
  <InputLabel id="colaborador-label">
    {"Posición"}
  </InputLabel>
  <Select
    labelId="colaborador-label"
    value={colaborador || "NONE"}
    id="colaborador"
    name="colaborador"
    label={"Posición"}
    onChange={handleChange}
  >
     <MenuItem value="NONE">
        <em>
         Seleccionar posición
        </em>
      </MenuItem>
    <MenuItem value="colaborador">Colaborador</MenuItem>
    <MenuItem value="team-leader">Team Leader</MenuItem>
    <MenuItem value="team-manager">Team Manager</MenuItem>
    <MenuItem value="manager">Manager</MenuItem>
    <MenuItem value="sr-manager">Sr Manager</MenuItem>
    <MenuItem value="director">Director</MenuItem>
  </Select>
</FormControl>


{ showTeamLeaderSelect && (
  <FormControl variant="outlined" className={classes.formControl}>
    <InputLabel id="team-leader-label">{"Team Leader"}</InputLabel>
    <Select
      error={teamError}
      labelId="team-leader-label"
      id="team-leader"
      value={teamLeader ? teamLeader.id || "" : "NONE"}
      name="team-leader"
      label={"Team Leader"}
      onChange={handleChange}
    >
      <MenuItem value="NONE">
        <em>
          {t(
            "admin.user-panel-user-dialog-input-select-without-team-leader",
            "Without Team Leader"
          )}
        </em>
      </MenuItem>

      {users &&
        users
          .filter(user => user.position === 2) 
          .map((user) => (
            <MenuItem key={user.id} value={user.id}>
  {user.name ? `${user.name} ${user.lastName}${user.email ? ` - ${user.email}` : ''}` : t(
    "admin.user-panel-user-dialog-input-select-without-name",
    "Without name"
  )}
</MenuItem>

          ))
      }

      
   
    </Select>
  </FormControl>
)}


                {showTeamManagerSelect && (<FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="team-manager-label">
                    {"Team Manager"}
                  </InputLabel>
                  <Select
                    error={teamError}
                    labelId="team-leader-label"
                    id="team-manager"
                    value={teamManager ? teamManager.id || "" : "NONE"}
                    name="team-manager"
                    label={"Team manager"}
                    onChange={handleChange}
                  >
                    <MenuItem value="NONE">
                      <em>
                        {t(
                          "admin.user-panel-user-dialog-input-select-without-team-manager",
                          "Without Team Manager"
                        )}
                      </em>
                    </MenuItem>

                    {users &&
  users
    .filter(user => user.position === 1)
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
  {user.name ? `${user.name} ${user.lastName}${user.email ? ` - ${user.email}` : ''}` : t(
    "admin.user-panel-user-dialog-input-select-without-name",
    "Without name"
  )}
</MenuItem>

    ))
}
                  </Select>
                </FormControl>)}

                {showManagerSelect && (<FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="team-manager-label">{"Manager"}</InputLabel>
                  <Select
                    error={teamError}
                    labelId="team-leader-label"
                    id="manager"
                    value={manager ? manager.id || "" : "NONE"}
                    name="manager"
                    label={"Manager"}
                    onChange={handleChange}
                  >
                    <MenuItem value="NONE">
                      <em>
                        {t(
                          "admin.user-panel-user-dialog-input-select-without-manager",
                          "Without Manager"
                        )}
                      </em>
                    </MenuItem>

                    {users &&
  users
    .filter(user => user.position === 4)
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
  {user.name ? `${user.name} ${user.lastName}${user.email ? ` - ${user.email}` : ''}` : t(
    "admin.user-panel-user-dialog-input-select-without-name",
    "Without name"
  )}
</MenuItem>

    ))
}
                  </Select>
                </FormControl>)}

                {showSrManagerSelect &&  (<FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="sr-manager-label">{"Sr Manager"}</InputLabel>
                  <Select
                    error={teamError}
                    labelId="sr-manager-label"
                    id="sr-manager"
                    value={srManager ? srManager.id || "" : "NONE"}
                    name="sr-manager"
                    label={"Sr manager"}
                    onChange={handleChange}
                  >
                    <MenuItem value="NONE">
                      <em>
                        {t(
                          "admin.user-panel-user-dialog-input-select-without-sr-manager",
                          "Without Sr Manager"
                        )}
                      </em>
                    </MenuItem>
                    {users &&
  users
    .filter(user => user.position === 5)
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
  {user.name ? `${user.name} ${user.lastName}${user.email ? ` - ${user.email}` : ''}` : t(
    "admin.user-panel-user-dialog-input-select-without-name",
    "Without name"
  )}
</MenuItem>

    ))
}
                  </Select>
                </FormControl>)}

                {showDirectorSelect && (
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="director-label">{"Director"}</InputLabel>
                  <Select
                    error={teamError}
                    labelId="team-leader-label"
                    id="director"
                    value={director ? director.id || "" : "NONE"}
                    name="director"
                    label={"Director"}
                    onChange={handleChange}
                  >
                    <MenuItem value="NONE">
                      <em>
                        {t(
                          "admin.user-panel-user-dialog-input-select-without-director",
                          "Without Director"
                        )}
                      </em>
                    </MenuItem>

                    {users &&
  users
    .filter(user => user.position === 6)
    .map((user) => (
      <MenuItem key={user.id} value={user.id}>
  {user.name ? `${user.name} ${user.lastName}${user.email ? ` - ${user.email}` : ''}` : t(
    "admin.user-panel-user-dialog-input-select-without-name",
    "Without name"
  )}
</MenuItem>

    ))
}
                  </Select>
                </FormControl>
                )}

                {roles && roles.length > 0 && (
                  <>
                    {" "}
                    <hr className="hr-styles" />
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t(
                                "admin.header-dropdown-view-conditions-table-role",
                                "Role"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {t(
                                "admin.user-panel-user-dialog-input-group",
                                "Group"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {t("admin.user-panel-table-actions", "Actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {roles.filter(r => r.role > 1).map((row, index) => (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {row.role === 2
                                  ? t(
                                      "admin.user-panel-table-supervisor",
                                      "Supervisor"
                                    )
                                  : t(
                                      "admin.user-panel-table-player",
                                      "Player"
                                    )}
                              </TableCell>

                              <TableCell align="left">
                                {row.team.name
                                  ? row.team.name
                                  : t(
                                      "admin.user-panel-user-dialog-input-select-without-name",
                                      "Without name"
                                    )}
                              </TableCell>

                              <TableCell align="left">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "50%",
                                  }}
                                >
                                  <Tooltip
                                    title={t("admin.actions-delete", "Delete")}
                                  >
                                    <DeleteIcon
                                      className="cursor-pointer"
                                      fontSize="small"
                                      onClick={() =>
                                        updateListRol(row, index, "DELETE")
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}

                {secRolTeam && secRolTeam.length > 0 && (
                  <>
                    {" "}
                    <hr className="hr-styles" />
                    <TableContainer component={Paper}>
                      <DialogTitle id="customized-dialog-title">
                        {t(
                          "admin.user-panel-user-dialog-input-secondary-team",
                          "Secondary group"
                        )}
                      </DialogTitle>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">
                              {t(
                                "admin.header-dropdown-view-conditions-table-role",
                                "Role"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {t(
                                "admin.user-panel-user-dialog-input-group",
                                "Group"
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {t("admin.user-panel-table-actions", "Actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={secTeam.id}>
                            <TableCell align="left">
                              {role === 2
                                ? t(
                                    "admin.user-panel-table-supervisor",
                                    "Supervisor"
                                  )
                                : t("admin.user-panel-table-player", "Player")}
                            </TableCell>

                            <TableCell align="left">{secTeam.name}</TableCell>

                            <TableCell align="left">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "50%",
                                }}
                              >
                                <Tooltip
                                  title={t("admin.actions-delete", "Delete")}
                                >
                                  <DeleteIcon
                                    className="cursor-pointer"
                                    fontSize="small"
                                    onClick={() => setSecRolTeam([])}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  {t("admin.header-dropdown-dialog-actions-cancel", "Cancel")}
                </Button>

                <Button onClick={onFormSubmit} color="primary">
                  {t("admin.header-dropdown-dialog-actions-save", "Save")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </>
    </>
  );
}

const mapDispatchToProps = {
  saveUserRequest,
  updateUserRequest,
  saveTeamRequest,
  saveSecondaryTeamRequest,
};

export default connect(null, mapDispatchToProps)(User);
