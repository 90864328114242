import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import SuggestionInboxService from "../../Services/SuggestionInboxService";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
}));
  
export default function ButtonStatus({ status, id }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [initialStatus, setInitialStatus] = React.useState(status);
    const anchorRef = React.useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
      console.log(status)
      setInitialStatus(status);
    }, []);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event, option) => {
      console.log(event.target)
      console.log(option)

      if ((anchorRef.current && anchorRef.current.contains(event.target))) {
        return;
      }

      if (option !== undefined){
        if (setSuggestionInboxStatus(option)) {
          toastr.success(
            t(
              "status-updated", 
              "The status of the suggestion has been updated",
            )
          );
          setInitialStatus(option);
        } else {
          toastr.error(
            t(
              "error-status-updated",
              "Error updating tip status"
            )
          );
        }
      }
  
      setOpen(false);
    };

    async function setSuggestionInboxStatus (option) {
      switch(option) {
        case 'CLOSED':
          await SuggestionInboxService.setSuggestionInboxAsClosed({ id: id })
            .then((response) => {
              return true;
            })
            .catch(() => {
              return false;
            });
          break;
        case 'RECEIVED':
          await SuggestionInboxService.setSuggestionInboxAsReceived({ id: id })
            .then((response) => {
              return true;
            })
            .catch(() => {
              return false;
            });
          break;
        case 'IN_PROGRESS':
          await SuggestionInboxService.setSuggestionInboxAsInProgress({ id: id })
            .then((response) => {
              return true;
            })
            .catch(() => {
              return false;
            });
          break;
        case 'COMPLETED':
          await SuggestionInboxService.setSuggestionInboxAsCompleted({ id: id })
            .then((response) => {
              return true;
            })
            .catch(() => {
              return false;
            });
          break;
        case 'DISMISSED':
          await SuggestionInboxService.setSuggestionInboxAsDismissed({ id: id })
            .then((response) => {
              return true;
            })
            .catch(() => {
              return false;
            });
          break;
      }
    }
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
  
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);
  
    return (
      <div className={classes.root}>
        <div>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{position: "static"}}
          >
            <span style={{backgroundColor: initialStatus == 'RECEIVED' ? '#ff988a' : initialStatus == 'IN_PROGRESS' ? '#fbe685' : initialStatus == 'COMPLETED' ? '#71ff75' : '' , padding: '4px', borderRadius: '6px'}}>
            {t('status-'+initialStatus)}
            </span>
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: '10'}}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        {initialStatus != 'CLOSED' && <MenuItem onClick={e => handleClose(e, 'CLOSED')}>Cerrado</MenuItem>}
                        {initialStatus != 'RECEIVED' && <MenuItem style={{background: '#ff988a'}} onClick={e => handleClose(e, 'RECEIVED')}>Recibido</MenuItem>}
                        {initialStatus != 'IN_PROGRESS' && <MenuItem style={{background: '#fbe685'}} onClick={e => handleClose(e, 'IN_PROGRESS')}>En Progreso</MenuItem>}
                        {initialStatus != 'COMPLETED' && <MenuItem style={{background: '#71ff75'}} onClick={e => handleClose(e, 'COMPLETED')}>Completado</MenuItem>}
                        {initialStatus != 'DISMISSED' && <MenuItem onClick={e => handleClose(e, 'DISMISSED')}>Rechazado</MenuItem>}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
  