import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  setSuggestionInbox: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/suggestioninbox`,
      data: data,
    }),

  setSuggestionInboxAsDismissed: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/suggestioninbox/markasdismissed`,
      data: data,
    }),
  
  setSuggestionInboxAsCompleted: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/suggestioninbox/markascompleted`,
      data: data,
    }),

  setSuggestionInboxAsClosed: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/suggestioninbox/markasclosed`,
      data: data,
    }),

  setSuggestionInboxAsReceived: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/suggestioninbox/markasreceived`,
      data: data,
    }),

  setSuggestionInboxAsInProgress: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/suggestioninbox/markasinprogress`,
      data: data,
    }),

  getSuggestionInbox: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/suggestioninbox`
    }),

  downloadSuggestionInbox: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/report/execute/BuzonDeSugerencias?_token=&_format=csv`
    })
};
