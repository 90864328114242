import * as suggestionInboxTypes from "../types/suggestionInboxTypes";

const {
  SUGGESTION_INBOX_ERROR,
  SUGGESTION_INBOX_CHARGING,
  SUGGESTION_INBOX_REQUEST,
} = suggestionInboxTypes;

const INITIAL_STATE = {
  suggestions_inbox: false,
  error_suggestions_inbox: false,
  suggestions_inbox_charging: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUGGESTION_INBOX_REQUEST:
      return {
        ...state,
        suggestions_inbox_charging: false,
        suggestions_inbox: action.payload,
        error_suggestions_inbox: false,
      };

    case SUGGESTION_INBOX_CHARGING:
      return { ...state, suggestions_inbox_charging: true, error_suggestions_inbox: false };

    case SUGGESTION_INBOX_ERROR:
      return {
        ...state,
        error_suggestions_inbox: action.payload,
        suggestions_inbox_charging: false,
        suggestions_inbox: [],
      };

    default:
      return state;
  }
};
