import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getUsers: (number = 1, size = 5, order = "asc") =>
  axios({
    method: "GET",
    url: `${environment.motivarnosBackend}/user?_number=${
      number - 1
    }&_size=${size}&_sort=name_${order}`,
  }),
getUsersIsActive: (number = 1, size = 5, order = "asc") =>
  axios({
    method: "GET",
    url: `${environment.motivarnosBackend}/user?_number=${
      number - 1
    }&_size=${size}&_sort=name_${order}&isActive=true`,
  }),
getUsersIsFalse: (number = 1, size = 5, order = "asc") =>
  axios({
    method: "GET",
    url: `${environment.motivarnosBackend}/user?_number=${
      number - 1
    }&_size=${size}&_sort=name_${order}&isActive=false`,
  }),
  saveTermsConditions: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/company/termsandconditions`,
      data: data,
    }),

  goToAdmin: () =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/gotoadmin`,
    }),

  goToPlayer: () =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/gotoplayer`,
    }),

  filterUsers: (number = 1, size = 5, filter, order = "asc") =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user?_number=${
        number - 1
      }&_size=${size}&username_like=%25${filter}%25&_sort=name_${order}`,
    }),
  filterActiveUsers: (number = 1, size = 5, filter, order = "asc") =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user?_number=${
        number - 1
      }&_size=${size}&username_like=%25${filter}%25&_sort=name_${order}&isActive=true`,
    }),
  filterBlockedUsers: (number = 1, size = 5, filter, order = "asc") =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user?_number=${
        number - 1
      }&_size=${size}&username_like=%25${filter}%25&_sort=name_${order}&isActive=false`,
    }),

  getUsersNotOwn: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user?id_not=own&_number=${
        number - 1
      }&_size=${size}`,
    }),

  getUsersAll: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/all?_number=${
        number - 1
      }&_size=${size}`,
    }),

  getUserAllCompany: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/allcompany`,
    }),

  getUsersCompact: (number = 1, size = 5, order = "desc") =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/compact?_number=${
        number - 1
      }&_size=${size}&_sort=name_${order}`,
    }),

  filterUsersCompact: (number = 1, size = 5, filter, order = "desc") =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/compact?_number=${
        number - 1
      }&_size=${size}&username_like=%25${filter}%25&_sort=name_${order}`,
    }),

  getUsersNotOwnCompact: (number, size) =>
    axios.get('/user/compact?id_not=own&_sort=name_asc', {params: {_number: number, _size: size}}),

  getUsersSupervisors: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/user?hasSupervisedTeams=true&_number=${number - 1}&_size=${size}`,
    }),

  getLoginByHour: (number = 1, size = 5, dateFrom, dateTo, teams) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/session/loginsbyweekdays${
        "?dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        (teams ? "&team.id_in=" + teams : "")
      }&_number=${number - 1}&_size=${size}`,
    }),

  downloadDate: (dateFrom, dateTo, teams) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/session/loginsbyweekdays${
        "?dateFrom=" +
        dateFrom +
        "&dateTo=" +
        dateTo +
        (teams ? "&team.id_in=" + teams : "")
      }&csv=true`,
    }),

  downloadComments: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/notificationcommentvote?csv=true`,
    }),

  getRanking: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account/ranking?_number=${
        number - 1
      }&_size=${size}`,
    }),

  GetHistoricalRanking: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account/ranking?_number=${
        number - 1
      }&_size=${size}&historical=true`,
    }),

  GetConditionsUser: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/user/lastlogin?_number=${number - 1}&_size=${size}`,
    }),

  downloadConditionsUser: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user/lastlogin?csv=true&_sort=lastLoginDate_desc`,
    }),

  downloadRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account/ranking?csv=true`,
    }),

  getUsersAccepted: (number = 1, size = 5) =>
    axios({
      method: "GET",
      url: `${
        environment.motivarnosBackend
      }/user?isAcceptedConditions=true&_number=${number - 1}&_size=${size}`,
    }),

  downloadUsers: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/user?csv=true`,
    }),

  saveUser: (data) =>
    axios({
      method: "POST",
      url: `${environment.motivarnosBackend}/user`,
      data: data,
    }),

  updateUser: (data) =>
      axios({
        method: "PUT",
        url: `${environment.motivarnosBackend}/user`,
        data: data,
      }),

  deleteUser: (id) =>
    axios({
      method: "DELETE",
      url: `${environment.motivarnosBackend}/user/${id}`,
    }),
  
  recoverPassword: (company, username) => {
    return axios.get(`/sesion/passwordreset?username=${username}&grupo=${''}&empresa=${company}`)
  },

  requestRecoverEmailPassword: (options) => {
    return axios.post(`/sesion/passwordresetoptions`, options);
  },

  resetPassword: (usercode, newPassword, hash) => {
    return axios.put('/admin/password/reset',{
      valor1: usercode,
      valor2: newPassword,
      valor3: hash,
    })
  }
};
