import axios from "axios";
import environment from "../../../../libs/environment";

export default {
  getMetricRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/metricconf/user`,
    }),
  GetMainRanking: () =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account`,
    }),

  GetSecondaryRanking: (category_award = '', fromDate = '', toDate = '') =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/account/ranking?awardCategory=${category_award}${
        fromDate != '' ? `&fromDate=${fromDate}T00:00:00` : `` }${
        toDate != '' ? `&toDate=${toDate}T00:00:00` : ``}`,
    }),

  downloadPointsRanking: (dateFrom = '', dateTo = '') =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/report/execute/MotivarnosPointsRanking?csv=true${
        dateFrom != '' ? `&created_ge=${dateFrom}` : ``}${
        dateTo != '' ? `&created_le=${dateTo}` : ``}`,
    }),

  downloadInsigniasRanking: (dateFrom = '', dateTo = '') =>
    axios({
      method: "GET",
      url: `${environment.motivarnosBackend}/report/execute/MotivarnosInsigniasRanking?csv=true${
        dateFrom != '' ? `&created_ge=${dateFrom}` : ``}${
        dateTo != '' ? `&created_le=${dateTo}` : ``}`,
    }),

  UpdateRanking: ({ id, sumary, amountCoin, amountPoint, userId }) =>
    axios({
      method: "POST",
      url:
        `${environment.motivarnosBackend}/accounttransaction?createdbyb.id=` +
        id,
      data: {
        sumary: sumary + "",
        amountCoin: amountCoin + "",
        amountPoint: amountPoint + "",
        status: "COMPLETED",
        user: {
          id: userId + "",
        },
      },
    }),
};
